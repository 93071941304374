import React from 'react';
import { graphql } from 'gatsby';
import { Layout, GeneralHero, Link } from '@components';
import { Arrow, LargeArrow } from '@styles/icons';
import { getKeyValue, compileRichText } from '@utils';
import { SMALL, LIGHT } from '@utils/vars';
import { ContentWrapper } from './plan.styles';
import {
  PaginationList, PaginationHeader, PaginationPrev, PaginationNext,
} from './plan-chapter.styles';

const PlanChapterTemplate = ({ location, data, pageContext }) => {
  const {
    site, page, globalSettings,
  } = data;

  const { locale } = pageContext;
  const { title: siteTitle, social } = getKeyValue(site, 'siteMetadata') || {};
  const {
    pageTitle, contentEntry, languages, pageDescription, metaImage, pageUrl,
    disableDonateBar, disableAlertBar, disableSplash, doNotIndex,
  } = page || {};
  const { url: metaImageUrl } = getKeyValue(metaImage, 'file') || {};
  const {
    content, title, heroImage, parentPlan, id, planDocumentDownloadChapterAnchorLink: anchor,
  } = contentEntry || {};

  const {
    navigation, enableAlertBar, alertBarLink, alertBarText, footerDisclaimer,
    footerSecondaryLinks, footerPrimaryLinks, footerSocialLinks, footerCopyright, footerAddress,
    enableDonateBar, donationBar, donateBarDisclaimer, enableSplash, splash, footerForm,
    footerEmails, footerPhoneNumber, formLabelLanguageOverrides, footerDonateByMail,
  } = globalSettings || {};

  const footerProps = {
    footerCopyright,
    footerDisclaimer,
    footerSocialLinks,
    footerPrimaryLinks,
    footerSecondaryLinks,
    footerForm,
    footerAddress,
    footerEmails,
    footerPhoneNumber,
    formLabelLanguageOverrides,
    footerDonateByMail,
  };

  const donateBarProps = {
    ...donationBar,
    donateBarDisclaimer,
  };

  const alertBarProps = {
    alertBarLink,
    alertBarText,
  };

  const splashProps = {
    ...splash,
    donateBarDisclaimer,
    formLabelLanguageOverrides,
  };

  const {
    page: parentPage,
    title: parentPageTitle,
    id: parentPageId,
    planChapters,
    planDocumentDownloadText, planDocument,
  } = parentPlan || {};

  const currentChapterIndex = planChapters && planChapters.findIndex((chapter) => (
    chapter.id === id));
  const nextChapter = planChapters && currentChapterIndex < planChapters.length
  && planChapters[currentChapterIndex + 1];
  const prevChapter = planChapters && currentChapterIndex > 0
  && planChapters[currentChapterIndex - 1];

  return (
    <Layout
      location={location}
      navigation={navigation}
      languages={languages}
      footerProps={footerProps}
      donateBarProps={donateBarProps}
      alertBarProps={alertBarProps}
      enableAlertBar={enableAlertBar && !disableAlertBar}
      enableDonateBar={enableDonateBar && !disableDonateBar}
      enableSplash={enableSplash && !disableSplash}
      metaDescription={pageDescription}
      metaUrl={`https://${process.env.GATSBY_HOST_DOMAIN}${pageUrl}`}
      metaTitle={`${pageTitle} | ${siteTitle}`}
      metaImageUrl={metaImageUrl}
      fbAppID={social.fbAppID}
      twitter={social.twitter}
      splashProps={splashProps}
      locale={locale}
      navTheme={LIGHT}
      fullWidth
      doNotIndex={doNotIndex}
    >
      <GeneralHero
        title={title}
        heroImage={heroImage}
        documentFileDownload={planDocument}
        documentFileDownloadAnchor={anchor}
        documentDownloadText={planDocumentDownloadText}
        parentPage={parentPage}
        parentPageTitle={parentPageTitle}
      />
      <ContentWrapper>
        {content && (
          compileRichText({ textTheme: 'primary', size: SMALL, locale })(content)
        )}
        <nav aria-label="pagination">
          <PaginationList>
            {prevChapter && prevChapter.title && prevChapter.page
            && prevChapter.page[0] && prevChapter.page[0].pageUrl && (
              <PaginationPrev key={prevChapter.id}>
                <Link href={prevChapter.page[0].pageUrl}>
                  <LargeArrow strokeColor="primaryHighlight" width="14.875px" height="31.375px" />
                  <span>{prevChapter.title}</span>
                </Link>
              </PaginationPrev>
            )}
            {nextChapter && nextChapter.title && nextChapter.page
            && nextChapter.page[0] && nextChapter.page[0].pageUrl && (
              <PaginationNext key={nextChapter.id}>
                <Link href={nextChapter.page[0].pageUrl}>
                  <span>{nextChapter.title}</span>
                  <LargeArrow strokeColor="primaryHighlight" width="14.875px" height="31.375px" />
                </Link>
              </PaginationNext>
            )}
            {parentPage && parentPageTitle && parentPage[0] && parentPage[0].pageUrl && (
              <PaginationHeader key={parentPageId}>
                <Link href={parentPage[0].pageUrl}>
                  <span>{parentPageTitle}</span>
                  <Arrow strokeColor="primary" height="14.4px" width="16px" />
                </Link>
              </PaginationHeader>
            )}
          </PaginationList>
        </nav>
      </ContentWrapper>
    </Layout>
  );
};

export default PlanChapterTemplate;

export const planChapterPageQuery = graphql`
  query PlanChapterBySlug($slug: String!, $locale: String!) {
    site {
      ...SiteMetadata
    }
    page: contentfulPage(pageUrl: { eq: $slug }) {
      ...PageMetadata
      contentEntry {
        ... on ContentfulPlanChapter {
          id
          title
          heroImage {
            description
            id
            fluid(quality: 90, maxWidth: 940) {
              ...GatsbyContentfulFluid_withWebp
            }
          }
          content {
            raw
            references {
              ... on ContentfulPage {
                id
                pageUrl
                contentful_id
                internal {
                  type
                }
              }
              ... on ContentfulAsset {
                contentful_id
                __typename
                file {
                  url
                }
                title
              }
            }
          }
          planDocumentDownloadChapterAnchorLink
          parentPlan {
            title
            id
            planDocumentDownloadText
            planDocument {
              file {
                url
              }
            }
            page {
              pageUrl
            }
            planChapters {
              id
              page {
                pageUrl
              }
              title
            }
          }
        }
      }
    }
    globalSettings: contentfulGlobalSettings(language: { eq: $locale }) {
      ...GlobalSettings
    }
  }
`;
