import styled, { css } from 'styled-components';
import { createBreakpoint } from '@styles';

export const PaginationList = styled.ul`
  width: 100%;
  margin-top: 32px;
  display: grid;
  grid-template-rows: auto 1fr;
  grid-template-columns: 50%;

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    margin-top: 60px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    margin-top: 64px;
  `)}
`;

export const PaginationListItem = styled.li`
  font-size: 14px;
  height: 100%;
  width: 100%;
  grid-row-start: 2;
  grid-row-end: 2;
  background-color: ${({ theme }) => theme.palette.lighterPrimary};
  transition: background 0.3s;

  &:hover {
    background-color: ${({ theme }) => theme.palette.secondaryHighlight};
  }

  a {
    display: flex;
    align-items: center;
    padding: 10px;
    font-weight: 700;
    line-height: 1;
    width: 100%;
    height: 100%;
    color: ${({ theme }) => theme.palette.primary};
    cursor: pointer;

    span {
      display: block;
      max-width: calc(100% - 24px);
    }

    &:hover {
      color: ${({ theme }) => theme.palette.primary};
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    font-size: 20px;
    a {
      padding: 20px;
      text-transform: uppercase;

      span {
        max-width: calc(100% - 32px);
      }
    }
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    font-size: 24px;
  `)}
`;

export const PaginationHeader = styled.li`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  grid-column-start: 1;
  grid-column-end: 3;
  width: 100%;
  padding: 10px 0px 12px;
  border-bottom: 2px solid  ${({ theme }) => theme.palette.primaryHighlight};

  a {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: flex-end;
    color: ${({ theme }) => theme.palette.primary};
    font-size: 16px;
    line-height: 1;
    font-weight: 700;

    svg {
      transition: transform 0.3s;
      transform: rotate(270deg);
      margin-left: 8px;
    }

    &:hover {
      svg {
        transform: rotate(270deg) translateX(40%);
        polygon {
          fill: ${({ theme }) => theme.palette.primaryHighlight};
        }
      }
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    font-size: 18px;
    padding: 10px 0px 14px;
  `)}

  ${({ theme }) => createBreakpoint(theme.breakpoints.desktopSmall, css`
    font-size: 20px;
    padding: 10px 0px 18px;
  `)}
`;

export const PaginationNext = styled(PaginationListItem)`
  margin-top: 0px;
  grid-column-start: 2;
  grid-column-end: 3;

  a {
    justify-content: flex-end;
    padding-right: 4px;
    svg {
      margin-left: 6px;
      margin-right: 4px;
      position: relative;
      bottom: -1.5px;
      transition: transform 0.3s;
      polygon {
        transition: all 0.3s;
      }
    }

    &:hover {
      svg {
        transform: translateX(40%);
        path {
          fill: ${({ theme }) => theme.palette.primary};
        }
      }
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    a {
      padding-right: 20px;
      svg {
        margin-left: 10px;
        margin-right: 8px;
      }
    }
  `)}
`;

export const PaginationPrev = styled(PaginationListItem)`
  margin-top: 0px;
  grid-column-start: 1;
  grid-column-end: 2;

  a {
    justify-content: flex-start;
    padding-left: 4px;
    svg {
      margin-left: 4px;
      margin-right: 6px;
      position: relative;
      bottom: -1.5px;
      transform: rotate(180deg);
      transition: transform 0.3s;
      polygon {
        transition: all 0.3s;
      }
    }

    &:hover {
      svg {
        transform: rotate(180deg) translateX(40%);
        path {
          fill: ${({ theme }) => theme.palette.primary};
        }
      }
    }
  }

  ${({ theme }) => createBreakpoint(theme.breakpoints.tablet, css`
    a {
      padding-left: 20px;
      svg {
        margin-left: 8px;
        margin-right: 10px;
      }
    }
  `)}
`;
